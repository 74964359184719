// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

//---- jQuery(UI) ----
import './src/jquery'
import './src/jquery-ui'

//---- JavintoAppPages
require("./packs/content/content_assets")
require("./packs/content/content_pages")

//---- www.woonsaem.nl
require("./packs/bootstrap_activation");
