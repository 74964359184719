if (typeof Turbo!= 'undefined') {
  document.addEventListener("turbo:load", function() {bind_content_pages_helpers(document)});
} else {
  $(document).ready( function() {bind_content_pages_helpers(document)});
}

function bind_content_pages_helpers(html) {
  /* Tree container: */
  $('.movable').sortable({
    axis: 'y',
    dropOnEmpty: false,
    cursor: 'crosshair',
    opacity: 0.4,
    delay: 200,
    scroll: true,
    update: function(event, ui){      
      var data= getDropTarget($(this).sortable('toArray'), $(ui.item).attr('id'));
      $.post( $(ui.item).data('move-url'), data);
    }
  });

  /* Sortable list containers: */
  $('[data-sort-url]').sortable({
    axis: $('[data-sort-url]').data('sort-axis'),     // Declare data-sort-axis with x (horizontal), y (vertical) or empty/false (grid)
    cursor: 'crosshair',
    opacity: 0.4,
    update: function(event, ui){
      $.ajax({
        type: 'post',
        data: $(this).sortable('serialize'),
        dataType: 'script',
        url: $(this).data('sort-url')})
    }
  });

  /** Html Inline Editable fields should be copied to their hidden input fields before submit */
  $('form', html).submit(function(event) {
    $(this).find('div.htmlEditable[data-html-editor-mode="inline"]').each(function(index, el) {      
      if (input_id= $(el).data('html-editor-input-id')) {
        $("#" + input_id).val( el.innerHTML);
      }
    });
  });

  /** Show/Hide SideContent textarea. toggle button label */
  $('.content_pages button[data-target="#collapse_side_content"]').click(function(event) {
    $(this).children('.on, .off').toggle();
  });
  /** SideContent text area default unfolded. When button visible on smaller viewport collapse. */
  // $('.content_pages button[data-target="#collapse_side_content"]:visible + #collapse_side_content[data-has-content="false"]').collapse();
}

/** Get the target position where to move the new dropped item to 
 The new_sort_list looks like after moving 'content_page_9':
 [
  "content_page_1",
  "",
  "content_page_7",
  "",
  "content_page_8",
  "",
  "",
  "content_page_2",
  "",
  "content_page_3",
  "content_page_9",
  "",
  "content_page_4",
  ""
]
*/
function getDropTarget(new_sort_list, drop_id) {
  // console.log(new_sort_list, drop_id);

  let target_id= null;
  let target_position= null;

  const new_position= new_sort_list.indexOf(drop_id);
  if (new_position==0) {
    target_id= new_sort_list[1];
    target_position= 'before';
  } else if (target_id= new_sort_list[new_position-1]) {
    // New position just after previous
    target_position= 'after';
  } else if (target_id= new_sort_list[new_position+1]) {
    target_position= 'before';
  }
  const record_id= $('#' + target_id).data('record-id');

  return {to: record_id, position: target_position};
}