document.addEventListener("click", function(event) {
  var target_form= event.target.form;

  switch(event.target.id) {
    case 'override_file_name_btn':
      if (target_elm= target_form.querySelector('#content_asset_name')) {
        target_elm.disabled= !target_elm.disabled;
      }
      event.stopPropagation();
      break;

    default:

      /* Button clicked to copy URL from given ID into clipboard */
      if (event.target.classList.contains('btn_content_content_assets_copy_text')) {
        let url= null;
        if (url = event.target.dataset.url) {
          contentAssetsCopyText(url);
        }
      }
  }

})

/* Copy text from given DOM element into Clipboard */
function contentAssetsCopyText(url){
  if("clipboard" in navigator) {
    // Modern browsers
    contentAssetsCopyToClipboard(url);
  } else {
    // Support Internet Explorer
    contentAssetsCopyCommand(url);
  }
}

/* Modern javascript function, works for Chrome as well. */
async function contentAssetsCopyToClipboard(text) {
  try {
    await navigator.clipboard.writeText(text);
    alert( text);
  } catch (err) {
    // Support incompatible browsers
    contentAssetsCopyCommand(text);
  }
}

/* Old javascript function, to support Internet Explorer */
function contentAssetsCopyCommand(text) {
  document.execCommand("copy", text);
  alert(text);
}